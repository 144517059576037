import { get } from 'lodash-es'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { MutationTree, ActionTree } from 'vuex'
import { FormType, Status, Form, StoreRouteParams } from '@/helpers/tsenums'
import { Tier } from '@/components/tiers/Tiers.interface'
import { DiscountCode } from '@/components/discount-codes/discountCode.interface'
import { FormState } from '@/helpers/enums'
import getStoreFormKey from '../helpers/getStoreFormKey'
import { DateOperator } from '@ha/helpers'
import { PutCallPaymentParameters } from '../components/payment-parameters/paymentParamters.interface'

declare module 'vuex/types/index' {
  interface Store<S> {
    $api: NuxtAxiosInstance
    $auth: NuxtAxiosInstance
  }
}

export interface Forms {
  [type: string]: Form
}

export interface Statuses {
  [type: string]: Status
}

export interface Creation {
  status: Status
  error: Object | null
}

declare interface BufferForm {
  organizationSlug: String
  formSlug: String
  formType: FormType
}

class State {
  overwrittenFormStates: { [key: string]: { state: string; createdAt: string } } = {} // ElasticSearch takes time to update and cannot be trusted for sometime after editing a form
  creation: Creation = {
    status: Status.SUCCESS,
    error: null
  }
  entities: Forms = {}
  statuses: Statuses = {}
  errors: Object = {}
  hasCustomCoordinates: Boolean = false
  deletedForms: BufferForm[] = []
}

export const getters = {
  getOverwrittenFormState: (state: State) => (key: string) => state.overwrittenFormStates[key],
  getFormState: (state: State) => (key: string) => state.entities[key]?.state
}

export const state = () => new State()

export const mutations = <MutationTree<State>>{
  OVERWRITE_CLIENT_FORM_STATE(storeState, { state, key }) {
    const createdAt = new DateOperator().toServerFormat()
    storeState.overwrittenFormStates[key] = {
      state,
      createdAt
    }
  },
  REMOVE_OVERWRITTEN_FORM_STATE(state, key) {
    delete state.overwrittenFormStates[key]
  },
  SET_FORM_CREATION_STATUS(state, status: Status) {
    state.creation.status = status
  },
  SET_FORM_CREATION_ERROR(state, error: Object) {
    state.creation.error = error
  },
  SET_FORM(state, { key, form }: { key: string; form: Form }) {
    state.entities = {
      ...state.entities,
      [key]: form
    }
  },
  SET_FORM_COLOR(state, { key, color }: { key: string; color: string }) {
    state.entities[key].color = color
  },
  SET_FORM_STATUS(state, { key, status }: { key: string; status: Status }) {
    state.statuses = {
      ...state.statuses,
      [key]: status
    }
  },
  SET_FORM_ERROR(state, { key, error }: { key: string; error: Object }) {
    state.errors = {
      ...state.errors,
      [key]: error
    }
  },
  SET_FORM_STATE(storeState, { key, state }: { key: string; state: string }) {
    storeState.entities[key].state = state
  },
  SET_FORM_BANNER_URL(state, { key, url }: { key: string; url: string }) {
    state.entities[key] = {
      ...state.entities[key],
      banner: url
    }
  },
  SET_FORM_THUMBNAIL_URL(state, { key, url }: { key: string; url: string }) {
    state.entities[key] = {
      ...state.entities[key],
      thumbnail: url
    }
  },
  SET_FORM_TOS_URL(state, { key, url }: { key: string; url: string }) {
    state.entities[key] = {
      ...state.entities[key],
      conditionsAndTermsFile: {
        publicUrl: url
      }
    }
  },
  SET_FORM_REFUND_AMOUNT(state, { key, amount }: { key: string; amount: number }) {
    state.entities[key] = {
      ...state.entities[key],
      refundAmount: amount
    }
  },
  SET_CUSTOM_COORDINATES_STATE(state, hasCustomCoordinates: boolean) {
    state.hasCustomCoordinates = hasCustomCoordinates
  },
  DELETE_FORM(state, key: string) {
    delete state.entities[key]
  },
  SET_ARCHIVED_STATUS(state, key: string) {
    if (!state.entities[key]) return
    state.entities[key].state = FormState.DISABLED
  },
  SET_DRAFT_STATUS(state, key: string) {
    if (!state.entities[key]) return
    state.entities[key].state = FormState.DRAFT
  },
  SET_FORM_PARTICIPANT_COUNT(
    state,
    { key, participantCount }: { key: string; participantCount: number }
  ) {
    if (!state.entities[key]) return
    state.entities[key].participantCount = participantCount
  },
  SET_FORM_TIERS(state, { key, tiers }: { key: string; tiers: Tier[] }) {
    if (!state.entities[key]) return
    state.entities[key].tiers = tiers
  },
  SET_FORM_DISCOUNT_CODES(
    state,
    { key, discountCodes }: { key: string; discountCodes: DiscountCode[] }
  ) {
    if (!state.entities[key]) return
    state.entities[key].discountCodes = discountCodes
  },

  ADD_DELETED_FORM(state, deletedForm: BufferForm) {
    state.deletedForms.push(deletedForm)
  },
  REMOVE_TERM_OF_SERVICE_FILE(state: State, storeRouteParams: StoreRouteParams) {
    delete state.entities[getStoreFormKey(storeRouteParams)].conditionsAndTermsFile
  }
}

export const actions = <ActionTree<State, any>>{
  async updatePaymentParameters(
    { commit, dispatch },
    {
      paymentParameters,
      storeRouteParams
    }: { paymentParameters: PutCallPaymentParameters; storeRouteParams: StoreRouteParams }
  ): Promise<Form> {
    const { data: form } = await this.$api.put<Form>(
      `/organizations/${storeRouteParams.orgSlug}/forms/${storeRouteParams.type}/${storeRouteParams.formSlug}/payment-terms`,
      paymentParameters
    )
    dispatch('tiers/fetchTiers', { routeParams: storeRouteParams, force: true }, { root: true })
    commit('SET_FORM', { key: getStoreFormKey(storeRouteParams), form: form })

    return form
  },
  clearObsoleteOverwrittenStates({ state, commit }) {
    Object.entries(state.overwrittenFormStates).forEach(([key, value]) => {
      // 30 secondes seems to give enough time to elasticSearch to update
      if (new DateOperator().diff(value.createdAt, 'seconds') >= 30) {
        commit('REMOVE_OVERWRITTEN_FORM_STATE', key)
      }
    })
  },
  fetchFormsWithFilter(
    { state, getters, dispatch },
    {
      orgSlug,
      formTypes,
      pageSize,
      states,
      continuationToken,
      formName
    }: {
      formTypes: FormType[]
      orgSlug: string
      pageSize: number
      states: string[]
      continuationToken: string
      formName: string
    }
  ) {
    let url = `/organizations/${orgSlug}/last-forms?pageSize=${pageSize}`

    if (continuationToken) {
      url = url + `&continuationToken=${continuationToken}`
    }

    if (states?.length > 0) {
      states.forEach((state) => {
        url = url + `&formStates=${state}`
      })
    }

    if (formTypes?.length > 0) {
      formTypes.forEach((formType) => {
        url = url + `&formTypes=${formType}`
      })
    }

    if (formName) {
      url = url + `&formName=${formName}`
    }

    return this.$api
      .$get(url.toString())
      .then((response) => {
        dispatch('clearObsoleteOverwrittenStates')

        const { data, ...responseBody } = response
        const slugDeletedForms =
          state.deletedForms
            ?.filter(
              (item) => item.organizationSlug === orgSlug && formTypes.includes(item.formType)
            )
            .map((item) => item.formSlug) || []

        const forms = response.data
          ?.filter((form) => {
            if (states.includes(FormState.DISABLED))
              return !slugDeletedForms.includes(form.formSlug)
            return !slugDeletedForms.includes(form.formSlug)
          })
          .map((form) => {
            // Used to display the real state of the form while elasticSearch updates
            const { organizationSlug: orgSlug, formType: type, formSlug } = form
            const overwrittenFormState = getters.getOverwrittenFormState(
              getStoreFormKey({ orgSlug, formSlug, type })
            )
            if (overwrittenFormState) {
              return { ...form, state: overwrittenFormState.state }
            }

            return form
          })

        return {
          data: forms ?? [],
          ...responseBody
        }
      })
      .catch((error) => {
        throw error
      })
  },

  fetchForm(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    const url = `/organizations/${orgSlug}/forms/${type}/${formSlug}`
    const key = getStoreFormKey({ orgSlug, formSlug, type })

    return this.$api
      .$get(url)
      .then((form) => {
        commit('SET_FORM', {
          key,
          form
        })
        commit('SET_FORM_STATUS', {
          key,
          status: Status.SUCCESS
        })
      })
      .catch((error) => {
        commit('SET_FORM', {
          key,
          form: null
        })
        commit('SET_FORM_ERROR', {
          key,
          error
        })
        commit('SET_FORM_STATUS', {
          key,
          status: Status.ERROR
        })
        throw error
      })
  },

  setFormState(
    { commit, getters },
    {
      orgSlug,
      formSlug,
      state,
      type
    }: { orgSlug: string; formSlug: string; type: FormType; state: string }
  ) {
    const key = getStoreFormKey({ orgSlug, formSlug, type })
    const previousFormState = getters.getFormState(key)

    commit('SET_FORM_STATE', {
      key,
      state
    })

    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/state`, {
        state
      })
      .then(() => commit('OVERWRITE_CLIENT_FORM_STATE', { state, key }))
      .catch((reason) => {
        console.error('Cannot change form state', reason)
        // reset state to previous value if the request failed
        commit('SET_FORM_STATE', {
          key,
          state: previousFormState
        })
      })
  },

  createForm(
    { commit },
    {
      orgSlug,
      form,
      type,
      useTemplate
    }: { orgSlug: string; form: Form; type: FormType; useTemplate: boolean }
  ) {
    commit('SET_FORM_CREATION_STATUS', Status.LOADING)

    return this.$api
      .$post(
        `/organizations/${orgSlug}/forms/${type}${useTemplate ? '?useTemplate=true' : ''}`,
        form
      )
      .then((data) => {
        const key = getStoreFormKey({ orgSlug, formSlug: data.formSlug, type })

        commit('SET_FORM', {
          key,
          form: data
        })
        commit('SET_FORM_ERROR', {
          key,
          error: null
        })
        commit('SET_FORM_STATUS', {
          key,
          status: Status.SUCCESS
        })

        commit('SET_FORM_CREATION_ERROR', null)
        commit('SET_FORM_CREATION_STATUS', Status.SUCCESS)
        commit(
          'organizations/ADD_ORGANIZATION_FORM_TYPE',
          { orgSlug, formType: data.formType },
          { root: true }
        )

        return data
      })
      .catch((error) => {
        commit('SET_FORM_CREATION_ERROR', error)
        commit('SET_FORM_CREATION_STATUS', Status.ERROR)
        throw error
      })
  },

  updateForm(
    { commit },
    {
      orgSlug,
      formSlug,
      form,
      type
    }: { orgSlug: string; formSlug: string; type: FormType; form: Form }
  ) {
    const key = getStoreFormKey({ orgSlug, formSlug, type })
    commit('SET_FORM_STATUS', {
      key,
      status: Status.LOADING
    })

    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}`, form)
      .then((data) => {
        commit('SET_FORM', {
          key,
          form: { ...form, ...data }
        })
        commit('SET_FORM_ERROR', {
          key,
          error: null
        })
        commit('SET_FORM_STATUS', {
          key,
          status: Status.SUCCESS
        })
        return data
      })
      .catch((error) => {
        commit('SET_FORM_ERROR', {
          key,
          error
        })
        commit('SET_FORM_STATUS', {
          key,
          status: Status.ERROR
        })
        throw error
      })
  },

  uploadFormBanner(
    { commit },
    {
      orgSlug,
      formSlug,
      file,
      type
    }: { orgSlug: string; formSlug: string; type: FormType; file: File }
  ) {
    const formData = new FormData()
    formData.append('file', file)

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/image/banner`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((data) => {
        const bannerUrl = get(data, 'fileName')
        commit('SET_FORM_BANNER_URL', {
          key: `${orgSlug}-${type}-${formSlug}`,
          url: bannerUrl
        })
        return bannerUrl
      })
  },

  uploadFormThumbnail(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      file
    }: { orgSlug: string; formSlug: string; type: FormType; file: File }
  ) {
    const formData = new FormData()
    formData.append('file', file)

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/image/logo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((data) => {
        const thumbnailUrl = get(data, 'fileName')
        commit('SET_FORM_THUMBNAIL_URL', {
          key: `${orgSlug}-${type}-${formSlug}`,
          url: thumbnailUrl
        })
        return thumbnailUrl
      })
  },
  removeTermOfServiceFile({ commit }, storeRouteParams: StoreRouteParams) {
    commit('REMOVE_TERM_OF_SERVICE_FILE', storeRouteParams)
  },
  uploadFormTos(
    { commit },
    {
      orgSlug,
      formSlug,
      type,
      file
    }: { orgSlug: string; formSlug: string; type: FormType; file: File }
  ) {
    const formData = new FormData()
    formData.append('file', file)
    // temporary placeholder, the "real" filename will be POSTed with the rest of the form.
    formData.append('fileName', file.name)

    return this.$api
      .$post(
        `/organizations/${orgSlug}/forms/${type}/${formSlug}/file-conditions-terms`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((file) => {
        commit('SET_FORM_TOS_URL', {
          key: `${orgSlug}-${type}-${formSlug}`,
          fileName: file.fileName,
          url: file.publicUrl
        })
        return file.publicUrl
      })
  },

  duplicateForm(
    { commit },
    {
      orgSlug,
      formSlug,
      title,
      updateDates,
      type
    }: { orgSlug: string; formSlug: string; type: FormType; title: string; updateDates: boolean }
  ) {
    commit('SET_FORM_CREATION_STATUS', Status.LOADING)

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/duplicate`, {
        title,
        updateDates
      })
      .then((form) => {
        const key = getStoreFormKey({ orgSlug, formSlug: form.formSlug, type })
        form.isDuplicated = true

        commit('SET_FORM', {
          key,
          form: form
        })
        commit('SET_FORM_ERROR', {
          key,
          error: null
        })
        commit('SET_FORM_STATUS', {
          key,
          status: Status.SUCCESS
        })

        commit('SET_FORM_CREATION_ERROR', null)
        commit('SET_FORM_CREATION_STATUS', Status.SUCCESS)

        return form
      })
      .catch((error) => {
        commit('SET_FORM_CREATION_ERROR', error)
        commit('SET_FORM_CREATION_STATUS', Status.ERROR)
        throw error
      })
  },

  askRefund(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    commit('SET_FORM_STATUS', {
      key: `${orgSlug}-${type}-${formSlug}`,
      status: Status.LOADING
    })

    return this.$api
      .$post(`/organizations/${orgSlug}/forms/${type}/${formSlug}/refund-request`)
      .then(() => {
        commit('SET_FORM_STATUS', {
          key: `${orgSlug}-${type}-${formSlug}`,
          status: Status.SUCCESS
        })
      })
      .catch((error) => {
        commit('SET_FORM_STATUS', {
          key: `${orgSlug}-${type}-${formSlug}`,
          status: Status.ERROR
        })
        throw error
      })
  },

  fetchRefundAmount(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    commit('SET_FORM_STATUS', {
      key: `${orgSlug}-${type}-${formSlug}`,
      status: Status.LOADING
    })

    return this.$api
      .$get(`/organizations/${orgSlug}/forms/${type}/${formSlug}/refund-amount`)
      .then((response) => {
        commit('SET_FORM_REFUND_AMOUNT', {
          key: `${orgSlug}-${type}-${formSlug}`,
          amount: response.amountToRefund
        })
        commit('SET_FORM_STATUS', {
          key: `${orgSlug}-${type}-${formSlug}`,
          status: Status.SUCCESS
        })
      })
      .catch((error) => {
        commit('SET_FORM_STATUS', {
          key: `${orgSlug}-${type}-${formSlug}`,
          status: Status.ERROR
        })
        throw error
      })
  },

  updateFormUrl(
    _,
    {
      orgSlug,
      formSlug,
      slug,
      type
    }: { orgSlug: string; formSlug: string; type: FormType; slug: string }
  ) {
    return this.$api.$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/slug`, { slug })
  },

  updateCustomCoordinatesState({ commit }, state: boolean) {
    commit('SET_CUSTOM_COORDINATES_STATE', state)
  },

  deleteForm(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    const state = FormState.DELETED
    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/state`, {
        state
      })
      .then(() => {
        const key = getStoreFormKey({ orgSlug, formSlug, type })
        commit('DELETE_FORM', key)
        commit('OVERWRITE_CLIENT_FORM_STATE', { state, key })
        commit('ADD_DELETED_FORM', { organizationSlug: orgSlug, formSlug, formType: type })
      })
  },

  archiveForm(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    const state = FormState.DISABLED

    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/state`, {
        state
      })
      .then(() => {
        const key = getStoreFormKey({ orgSlug, formSlug, type })
        commit('OVERWRITE_CLIENT_FORM_STATE', { state, key })
        commit('SET_ARCHIVED_STATUS', key)
      })
  },
  unarchiveForm(
    { commit },
    { orgSlug, formSlug, type }: { orgSlug: string; formSlug: string; type: FormType }
  ) {
    const state = FormState.DRAFT
    return this.$api
      .$put(`/organizations/${orgSlug}/forms/${type}/${formSlug}/state`, {
        state
      })
      .then(() => {
        const key = getStoreFormKey({ orgSlug, formSlug, type })
        commit('OVERWRITE_CLIENT_FORM_STATE', { state, key })
        commit('SET_DRAFT_STATUS', key)
      })
  }
}
